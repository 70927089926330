<script lang="ts" setup>
const { locale } = useT();
useAppInitData();
</script>

<template>
	<div :class="`lang-${locale}`">
		<NuxtLayout>
			<NuxtPage />
		</NuxtLayout>
	</div>
</template>

<style lang="scss"></style>
