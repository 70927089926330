import { default as _91landing_93GHBR6Q0fDpMeta } from "/app/apps/funrize/pages/[landing].vue?macro=true";
import { default as affiliatesZWTUlj4TbSMeta } from "/app/apps/funrize/pages/affiliates.vue?macro=true";
import { default as cashIframeRvtKvon4w6Meta } from "/app/apps/funrize/pages/cashIframe.vue?macro=true";
import { default as indexuosloNzxwTMeta } from "/app/apps/funrize/pages/game/index.vue?macro=true";
import { default as responsible_45playwEQRp0ZsNVMeta } from "/app/apps/funrize/pages/game/responsible-play.vue?macro=true";
import { default as _91pageName_93XRcszKGmGtMeta } from "/app/apps/funrize/pages/games/[pageName].vue?macro=true";
import { default as how_45it_45works2IA1Del7ZRMeta } from "/app/apps/funrize/pages/how-it-works.vue?macro=true";
import { default as indexeljFkDcMPiMeta } from "/app/apps/funrize/pages/index.vue?macro=true";
import { default as invite_45friendsW2RDMuGSiPMeta } from "/app/apps/funrize/pages/invite-friends.vue?macro=true";
import { default as _91pageName_93exvTNJRaD1Meta } from "/app/apps/funrize/pages/issues/[pageName].vue?macro=true";
import { default as favoritesmHDvPI0UsjMeta } from "/app/apps/funrize/pages/issues/favorites.vue?macro=true";
import { default as authbhjYJPk9yiMeta } from "/app/apps/funrize/pages/not-released/auth.vue?macro=true";
import { default as onboarding_45showcaseEh8yNmXiqkMeta } from "/app/apps/funrize/pages/not-released/onboarding-showcase.vue?macro=true";
import { default as onboarding_45test_45firstAmDjUMqwwmMeta } from "/app/apps/funrize/pages/not-released/onboarding-test-first.vue?macro=true";
import { default as onboarding_45test_45fourthFbLQK0mxF4Meta } from "/app/apps/funrize/pages/not-released/onboarding-test-fourth.vue?macro=true";
import { default as onboarding_45test_45secondj7ymdzWFlWMeta } from "/app/apps/funrize/pages/not-released/onboarding-test-second.vue?macro=true";
import { default as onboarding_45test_45third5SX093fJLQMeta } from "/app/apps/funrize/pages/not-released/onboarding-test-third.vue?macro=true";
import { default as translations_45showcaseDhOyht5es1Meta } from "/app/apps/funrize/pages/not-released/translations-showcase.vue?macro=true";
import { default as _91name_93tBK6G0OTpeMeta } from "/app/apps/funrize/pages/page/[name].vue?macro=true";
import { default as payments_45historylXw3KGXLefMeta } from "/app/apps/funrize/pages/payments-history.vue?macro=true";
import { default as prize_45dropsnkul7k3odVMeta } from "/app/apps/funrize/pages/prize-drops.vue?macro=true";
import { default as promotionsNA0XjnksbQMeta } from "/app/apps/funrize/pages/promotions.vue?macro=true";
import { default as races1A66gVZhuAMeta } from "/app/apps/funrize/pages/races.vue?macro=true";
import { default as referral_45infovmY9Sc8cwlMeta } from "/app/apps/funrize/pages/referral-info.vue?macro=true";
import { default as scratch_45cards5LKpcP9oaDMeta } from "/app/apps/funrize/pages/scratch-cards.vue?macro=true";
export default [
  {
    name: _91landing_93GHBR6Q0fDpMeta?.name ?? "landing___en___default",
    path: _91landing_93GHBR6Q0fDpMeta?.path ?? "/:landing()",
    meta: _91landing_93GHBR6Q0fDpMeta || {},
    alias: _91landing_93GHBR6Q0fDpMeta?.alias || [],
    redirect: _91landing_93GHBR6Q0fDpMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/[landing].vue").then(m => m.default || m)
  },
  {
    name: _91landing_93GHBR6Q0fDpMeta?.name ?? "landing___en",
    path: _91landing_93GHBR6Q0fDpMeta?.path ?? "/en/:landing()",
    meta: _91landing_93GHBR6Q0fDpMeta || {},
    alias: _91landing_93GHBR6Q0fDpMeta?.alias || [],
    redirect: _91landing_93GHBR6Q0fDpMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/[landing].vue").then(m => m.default || m)
  },
  {
    name: affiliatesZWTUlj4TbSMeta?.name ?? "affiliates___en___default",
    path: affiliatesZWTUlj4TbSMeta?.path ?? "/affiliates",
    meta: affiliatesZWTUlj4TbSMeta || {},
    alias: affiliatesZWTUlj4TbSMeta?.alias || [],
    redirect: affiliatesZWTUlj4TbSMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/affiliates.vue").then(m => m.default || m)
  },
  {
    name: affiliatesZWTUlj4TbSMeta?.name ?? "affiliates___en",
    path: affiliatesZWTUlj4TbSMeta?.path ?? "/en/affiliates",
    meta: affiliatesZWTUlj4TbSMeta || {},
    alias: affiliatesZWTUlj4TbSMeta?.alias || [],
    redirect: affiliatesZWTUlj4TbSMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/affiliates.vue").then(m => m.default || m)
  },
  {
    name: cashIframeRvtKvon4w6Meta?.name ?? "cashIframe___en___default",
    path: cashIframeRvtKvon4w6Meta?.path ?? "/cashIframe",
    meta: cashIframeRvtKvon4w6Meta || {},
    alias: cashIframeRvtKvon4w6Meta?.alias || [],
    redirect: cashIframeRvtKvon4w6Meta?.redirect,
    component: () => import("/app/apps/funrize/pages/cashIframe.vue").then(m => m.default || m)
  },
  {
    name: cashIframeRvtKvon4w6Meta?.name ?? "cashIframe___en",
    path: cashIframeRvtKvon4w6Meta?.path ?? "/en/cashIframe",
    meta: cashIframeRvtKvon4w6Meta || {},
    alias: cashIframeRvtKvon4w6Meta?.alias || [],
    redirect: cashIframeRvtKvon4w6Meta?.redirect,
    component: () => import("/app/apps/funrize/pages/cashIframe.vue").then(m => m.default || m)
  },
  {
    name: indexuosloNzxwTMeta?.name ?? "game___en___default",
    path: indexuosloNzxwTMeta?.path ?? "/game",
    meta: indexuosloNzxwTMeta || {},
    alias: indexuosloNzxwTMeta?.alias || [],
    redirect: indexuosloNzxwTMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/game/index.vue").then(m => m.default || m)
  },
  {
    name: indexuosloNzxwTMeta?.name ?? "game___en",
    path: indexuosloNzxwTMeta?.path ?? "/en/game",
    meta: indexuosloNzxwTMeta || {},
    alias: indexuosloNzxwTMeta?.alias || [],
    redirect: indexuosloNzxwTMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/game/index.vue").then(m => m.default || m)
  },
  {
    name: responsible_45playwEQRp0ZsNVMeta?.name ?? "game-responsible-play___en___default",
    path: responsible_45playwEQRp0ZsNVMeta?.path ?? "/game/responsible-play",
    meta: responsible_45playwEQRp0ZsNVMeta || {},
    alias: responsible_45playwEQRp0ZsNVMeta?.alias || [],
    redirect: responsible_45playwEQRp0ZsNVMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/game/responsible-play.vue").then(m => m.default || m)
  },
  {
    name: responsible_45playwEQRp0ZsNVMeta?.name ?? "game-responsible-play___en",
    path: responsible_45playwEQRp0ZsNVMeta?.path ?? "/en/game/responsible-play",
    meta: responsible_45playwEQRp0ZsNVMeta || {},
    alias: responsible_45playwEQRp0ZsNVMeta?.alias || [],
    redirect: responsible_45playwEQRp0ZsNVMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/game/responsible-play.vue").then(m => m.default || m)
  },
  {
    name: _91pageName_93XRcszKGmGtMeta?.name ?? "games-pageName___en___default",
    path: _91pageName_93XRcszKGmGtMeta?.path ?? "/games/:pageName()",
    meta: _91pageName_93XRcszKGmGtMeta || {},
    alias: _91pageName_93XRcszKGmGtMeta?.alias || [],
    redirect: _91pageName_93XRcszKGmGtMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/games/[pageName].vue").then(m => m.default || m)
  },
  {
    name: _91pageName_93XRcszKGmGtMeta?.name ?? "games-pageName___en",
    path: _91pageName_93XRcszKGmGtMeta?.path ?? "/en/games/:pageName()",
    meta: _91pageName_93XRcszKGmGtMeta || {},
    alias: _91pageName_93XRcszKGmGtMeta?.alias || [],
    redirect: _91pageName_93XRcszKGmGtMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/games/[pageName].vue").then(m => m.default || m)
  },
  {
    name: how_45it_45works2IA1Del7ZRMeta?.name ?? "how-it-works___en___default",
    path: how_45it_45works2IA1Del7ZRMeta?.path ?? "/how-it-works",
    meta: how_45it_45works2IA1Del7ZRMeta || {},
    alias: how_45it_45works2IA1Del7ZRMeta?.alias || [],
    redirect: how_45it_45works2IA1Del7ZRMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/how-it-works.vue").then(m => m.default || m)
  },
  {
    name: how_45it_45works2IA1Del7ZRMeta?.name ?? "how-it-works___en",
    path: how_45it_45works2IA1Del7ZRMeta?.path ?? "/en/how-it-works",
    meta: how_45it_45works2IA1Del7ZRMeta || {},
    alias: how_45it_45works2IA1Del7ZRMeta?.alias || [],
    redirect: how_45it_45works2IA1Del7ZRMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/how-it-works.vue").then(m => m.default || m)
  },
  {
    name: indexeljFkDcMPiMeta?.name ?? "index___en___default",
    path: indexeljFkDcMPiMeta?.path ?? "/",
    meta: indexeljFkDcMPiMeta || {},
    alias: indexeljFkDcMPiMeta?.alias || [],
    redirect: indexeljFkDcMPiMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexeljFkDcMPiMeta?.name ?? "index___en",
    path: indexeljFkDcMPiMeta?.path ?? "/en",
    meta: indexeljFkDcMPiMeta || {},
    alias: indexeljFkDcMPiMeta?.alias || [],
    redirect: indexeljFkDcMPiMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/index.vue").then(m => m.default || m)
  },
  {
    name: invite_45friendsW2RDMuGSiPMeta?.name ?? "invite-friends___en___default",
    path: invite_45friendsW2RDMuGSiPMeta?.path ?? "/invite-friends",
    meta: invite_45friendsW2RDMuGSiPMeta || {},
    alias: invite_45friendsW2RDMuGSiPMeta?.alias || [],
    redirect: invite_45friendsW2RDMuGSiPMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/invite-friends.vue").then(m => m.default || m)
  },
  {
    name: invite_45friendsW2RDMuGSiPMeta?.name ?? "invite-friends___en",
    path: invite_45friendsW2RDMuGSiPMeta?.path ?? "/en/invite-friends",
    meta: invite_45friendsW2RDMuGSiPMeta || {},
    alias: invite_45friendsW2RDMuGSiPMeta?.alias || [],
    redirect: invite_45friendsW2RDMuGSiPMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/invite-friends.vue").then(m => m.default || m)
  },
  {
    name: _91pageName_93exvTNJRaD1Meta?.name ?? "issues-pageName___en___default",
    path: _91pageName_93exvTNJRaD1Meta?.path ?? "/issues/:pageName()",
    meta: _91pageName_93exvTNJRaD1Meta || {},
    alias: _91pageName_93exvTNJRaD1Meta?.alias || [],
    redirect: _91pageName_93exvTNJRaD1Meta?.redirect,
    component: () => import("/app/apps/funrize/pages/issues/[pageName].vue").then(m => m.default || m)
  },
  {
    name: _91pageName_93exvTNJRaD1Meta?.name ?? "issues-pageName___en",
    path: _91pageName_93exvTNJRaD1Meta?.path ?? "/en/issues/:pageName()",
    meta: _91pageName_93exvTNJRaD1Meta || {},
    alias: _91pageName_93exvTNJRaD1Meta?.alias || [],
    redirect: _91pageName_93exvTNJRaD1Meta?.redirect,
    component: () => import("/app/apps/funrize/pages/issues/[pageName].vue").then(m => m.default || m)
  },
  {
    name: favoritesmHDvPI0UsjMeta?.name ?? "issues-favorites___en___default",
    path: favoritesmHDvPI0UsjMeta?.path ?? "/issues/favorites",
    meta: favoritesmHDvPI0UsjMeta || {},
    alias: favoritesmHDvPI0UsjMeta?.alias || [],
    redirect: favoritesmHDvPI0UsjMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/issues/favorites.vue").then(m => m.default || m)
  },
  {
    name: favoritesmHDvPI0UsjMeta?.name ?? "issues-favorites___en",
    path: favoritesmHDvPI0UsjMeta?.path ?? "/en/issues/favorites",
    meta: favoritesmHDvPI0UsjMeta || {},
    alias: favoritesmHDvPI0UsjMeta?.alias || [],
    redirect: favoritesmHDvPI0UsjMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/issues/favorites.vue").then(m => m.default || m)
  },
  {
    name: authbhjYJPk9yiMeta?.name ?? "not-released-auth___en___default",
    path: authbhjYJPk9yiMeta?.path ?? "/not-released/auth",
    meta: authbhjYJPk9yiMeta || {},
    alias: authbhjYJPk9yiMeta?.alias || [],
    redirect: authbhjYJPk9yiMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/not-released/auth.vue").then(m => m.default || m)
  },
  {
    name: authbhjYJPk9yiMeta?.name ?? "not-released-auth___en",
    path: authbhjYJPk9yiMeta?.path ?? "/en/not-released/auth",
    meta: authbhjYJPk9yiMeta || {},
    alias: authbhjYJPk9yiMeta?.alias || [],
    redirect: authbhjYJPk9yiMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/not-released/auth.vue").then(m => m.default || m)
  },
  {
    name: onboarding_45showcaseEh8yNmXiqkMeta?.name ?? "not-released-onboarding-showcase___en___default",
    path: onboarding_45showcaseEh8yNmXiqkMeta?.path ?? "/not-released/onboarding-showcase",
    meta: onboarding_45showcaseEh8yNmXiqkMeta || {},
    alias: onboarding_45showcaseEh8yNmXiqkMeta?.alias || [],
    redirect: onboarding_45showcaseEh8yNmXiqkMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/not-released/onboarding-showcase.vue").then(m => m.default || m)
  },
  {
    name: onboarding_45showcaseEh8yNmXiqkMeta?.name ?? "not-released-onboarding-showcase___en",
    path: onboarding_45showcaseEh8yNmXiqkMeta?.path ?? "/en/not-released/onboarding-showcase",
    meta: onboarding_45showcaseEh8yNmXiqkMeta || {},
    alias: onboarding_45showcaseEh8yNmXiqkMeta?.alias || [],
    redirect: onboarding_45showcaseEh8yNmXiqkMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/not-released/onboarding-showcase.vue").then(m => m.default || m)
  },
  {
    name: onboarding_45test_45firstAmDjUMqwwmMeta?.name ?? "not-released-onboarding-test-first___en___default",
    path: onboarding_45test_45firstAmDjUMqwwmMeta?.path ?? "/not-released/onboarding-test-first",
    meta: onboarding_45test_45firstAmDjUMqwwmMeta || {},
    alias: onboarding_45test_45firstAmDjUMqwwmMeta?.alias || [],
    redirect: onboarding_45test_45firstAmDjUMqwwmMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/not-released/onboarding-test-first.vue").then(m => m.default || m)
  },
  {
    name: onboarding_45test_45firstAmDjUMqwwmMeta?.name ?? "not-released-onboarding-test-first___en",
    path: onboarding_45test_45firstAmDjUMqwwmMeta?.path ?? "/en/not-released/onboarding-test-first",
    meta: onboarding_45test_45firstAmDjUMqwwmMeta || {},
    alias: onboarding_45test_45firstAmDjUMqwwmMeta?.alias || [],
    redirect: onboarding_45test_45firstAmDjUMqwwmMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/not-released/onboarding-test-first.vue").then(m => m.default || m)
  },
  {
    name: onboarding_45test_45fourthFbLQK0mxF4Meta?.name ?? "not-released-onboarding-test-fourth___en___default",
    path: onboarding_45test_45fourthFbLQK0mxF4Meta?.path ?? "/not-released/onboarding-test-fourth",
    meta: onboarding_45test_45fourthFbLQK0mxF4Meta || {},
    alias: onboarding_45test_45fourthFbLQK0mxF4Meta?.alias || [],
    redirect: onboarding_45test_45fourthFbLQK0mxF4Meta?.redirect,
    component: () => import("/app/apps/funrize/pages/not-released/onboarding-test-fourth.vue").then(m => m.default || m)
  },
  {
    name: onboarding_45test_45fourthFbLQK0mxF4Meta?.name ?? "not-released-onboarding-test-fourth___en",
    path: onboarding_45test_45fourthFbLQK0mxF4Meta?.path ?? "/en/not-released/onboarding-test-fourth",
    meta: onboarding_45test_45fourthFbLQK0mxF4Meta || {},
    alias: onboarding_45test_45fourthFbLQK0mxF4Meta?.alias || [],
    redirect: onboarding_45test_45fourthFbLQK0mxF4Meta?.redirect,
    component: () => import("/app/apps/funrize/pages/not-released/onboarding-test-fourth.vue").then(m => m.default || m)
  },
  {
    name: onboarding_45test_45secondj7ymdzWFlWMeta?.name ?? "not-released-onboarding-test-second___en___default",
    path: onboarding_45test_45secondj7ymdzWFlWMeta?.path ?? "/not-released/onboarding-test-second",
    meta: onboarding_45test_45secondj7ymdzWFlWMeta || {},
    alias: onboarding_45test_45secondj7ymdzWFlWMeta?.alias || [],
    redirect: onboarding_45test_45secondj7ymdzWFlWMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/not-released/onboarding-test-second.vue").then(m => m.default || m)
  },
  {
    name: onboarding_45test_45secondj7ymdzWFlWMeta?.name ?? "not-released-onboarding-test-second___en",
    path: onboarding_45test_45secondj7ymdzWFlWMeta?.path ?? "/en/not-released/onboarding-test-second",
    meta: onboarding_45test_45secondj7ymdzWFlWMeta || {},
    alias: onboarding_45test_45secondj7ymdzWFlWMeta?.alias || [],
    redirect: onboarding_45test_45secondj7ymdzWFlWMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/not-released/onboarding-test-second.vue").then(m => m.default || m)
  },
  {
    name: onboarding_45test_45third5SX093fJLQMeta?.name ?? "not-released-onboarding-test-third___en___default",
    path: onboarding_45test_45third5SX093fJLQMeta?.path ?? "/not-released/onboarding-test-third",
    meta: onboarding_45test_45third5SX093fJLQMeta || {},
    alias: onboarding_45test_45third5SX093fJLQMeta?.alias || [],
    redirect: onboarding_45test_45third5SX093fJLQMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/not-released/onboarding-test-third.vue").then(m => m.default || m)
  },
  {
    name: onboarding_45test_45third5SX093fJLQMeta?.name ?? "not-released-onboarding-test-third___en",
    path: onboarding_45test_45third5SX093fJLQMeta?.path ?? "/en/not-released/onboarding-test-third",
    meta: onboarding_45test_45third5SX093fJLQMeta || {},
    alias: onboarding_45test_45third5SX093fJLQMeta?.alias || [],
    redirect: onboarding_45test_45third5SX093fJLQMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/not-released/onboarding-test-third.vue").then(m => m.default || m)
  },
  {
    name: translations_45showcaseDhOyht5es1Meta?.name ?? "not-released-translations-showcase___en___default",
    path: translations_45showcaseDhOyht5es1Meta?.path ?? "/not-released/translations-showcase",
    meta: translations_45showcaseDhOyht5es1Meta || {},
    alias: translations_45showcaseDhOyht5es1Meta?.alias || [],
    redirect: translations_45showcaseDhOyht5es1Meta?.redirect,
    component: () => import("/app/apps/funrize/pages/not-released/translations-showcase.vue").then(m => m.default || m)
  },
  {
    name: translations_45showcaseDhOyht5es1Meta?.name ?? "not-released-translations-showcase___en",
    path: translations_45showcaseDhOyht5es1Meta?.path ?? "/en/not-released/translations-showcase",
    meta: translations_45showcaseDhOyht5es1Meta || {},
    alias: translations_45showcaseDhOyht5es1Meta?.alias || [],
    redirect: translations_45showcaseDhOyht5es1Meta?.redirect,
    component: () => import("/app/apps/funrize/pages/not-released/translations-showcase.vue").then(m => m.default || m)
  },
  {
    name: _91name_93tBK6G0OTpeMeta?.name ?? "page-name___en___default",
    path: _91name_93tBK6G0OTpeMeta?.path ?? "/page/:name()",
    meta: _91name_93tBK6G0OTpeMeta || {},
    alias: _91name_93tBK6G0OTpeMeta?.alias || [],
    redirect: _91name_93tBK6G0OTpeMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/page/[name].vue").then(m => m.default || m)
  },
  {
    name: _91name_93tBK6G0OTpeMeta?.name ?? "page-name___en",
    path: _91name_93tBK6G0OTpeMeta?.path ?? "/en/page/:name()",
    meta: _91name_93tBK6G0OTpeMeta || {},
    alias: _91name_93tBK6G0OTpeMeta?.alias || [],
    redirect: _91name_93tBK6G0OTpeMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/page/[name].vue").then(m => m.default || m)
  },
  {
    name: payments_45historylXw3KGXLefMeta?.name ?? "payments-history___en___default",
    path: payments_45historylXw3KGXLefMeta?.path ?? "/payments-history",
    meta: payments_45historylXw3KGXLefMeta || {},
    alias: payments_45historylXw3KGXLefMeta?.alias || [],
    redirect: payments_45historylXw3KGXLefMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/payments-history.vue").then(m => m.default || m)
  },
  {
    name: payments_45historylXw3KGXLefMeta?.name ?? "payments-history___en",
    path: payments_45historylXw3KGXLefMeta?.path ?? "/en/payments-history",
    meta: payments_45historylXw3KGXLefMeta || {},
    alias: payments_45historylXw3KGXLefMeta?.alias || [],
    redirect: payments_45historylXw3KGXLefMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/payments-history.vue").then(m => m.default || m)
  },
  {
    name: prize_45dropsnkul7k3odVMeta?.name ?? "prize-drops___en___default",
    path: prize_45dropsnkul7k3odVMeta?.path ?? "/prize-drops",
    meta: prize_45dropsnkul7k3odVMeta || {},
    alias: prize_45dropsnkul7k3odVMeta?.alias || [],
    redirect: prize_45dropsnkul7k3odVMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/prize-drops.vue").then(m => m.default || m)
  },
  {
    name: prize_45dropsnkul7k3odVMeta?.name ?? "prize-drops___en",
    path: prize_45dropsnkul7k3odVMeta?.path ?? "/en/prize-drops",
    meta: prize_45dropsnkul7k3odVMeta || {},
    alias: prize_45dropsnkul7k3odVMeta?.alias || [],
    redirect: prize_45dropsnkul7k3odVMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/prize-drops.vue").then(m => m.default || m)
  },
  {
    name: promotionsNA0XjnksbQMeta?.name ?? "promotions___en___default",
    path: promotionsNA0XjnksbQMeta?.path ?? "/promotions",
    meta: promotionsNA0XjnksbQMeta || {},
    alias: promotionsNA0XjnksbQMeta?.alias || [],
    redirect: promotionsNA0XjnksbQMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/promotions.vue").then(m => m.default || m)
  },
  {
    name: promotionsNA0XjnksbQMeta?.name ?? "promotions___en",
    path: promotionsNA0XjnksbQMeta?.path ?? "/en/promotions",
    meta: promotionsNA0XjnksbQMeta || {},
    alias: promotionsNA0XjnksbQMeta?.alias || [],
    redirect: promotionsNA0XjnksbQMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/promotions.vue").then(m => m.default || m)
  },
  {
    name: races1A66gVZhuAMeta?.name ?? "races___en___default",
    path: races1A66gVZhuAMeta?.path ?? "/races",
    meta: races1A66gVZhuAMeta || {},
    alias: races1A66gVZhuAMeta?.alias || [],
    redirect: races1A66gVZhuAMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/races.vue").then(m => m.default || m)
  },
  {
    name: races1A66gVZhuAMeta?.name ?? "races___en",
    path: races1A66gVZhuAMeta?.path ?? "/en/races",
    meta: races1A66gVZhuAMeta || {},
    alias: races1A66gVZhuAMeta?.alias || [],
    redirect: races1A66gVZhuAMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/races.vue").then(m => m.default || m)
  },
  {
    name: referral_45infovmY9Sc8cwlMeta?.name ?? "referral-info___en___default",
    path: referral_45infovmY9Sc8cwlMeta?.path ?? "/referral-info",
    meta: referral_45infovmY9Sc8cwlMeta || {},
    alias: referral_45infovmY9Sc8cwlMeta?.alias || [],
    redirect: referral_45infovmY9Sc8cwlMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/referral-info.vue").then(m => m.default || m)
  },
  {
    name: referral_45infovmY9Sc8cwlMeta?.name ?? "referral-info___en",
    path: referral_45infovmY9Sc8cwlMeta?.path ?? "/en/referral-info",
    meta: referral_45infovmY9Sc8cwlMeta || {},
    alias: referral_45infovmY9Sc8cwlMeta?.alias || [],
    redirect: referral_45infovmY9Sc8cwlMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/referral-info.vue").then(m => m.default || m)
  },
  {
    name: scratch_45cards5LKpcP9oaDMeta?.name ?? "scratch-cards___en___default",
    path: scratch_45cards5LKpcP9oaDMeta?.path ?? "/scratch-cards",
    meta: scratch_45cards5LKpcP9oaDMeta || {},
    alias: scratch_45cards5LKpcP9oaDMeta?.alias || [],
    redirect: scratch_45cards5LKpcP9oaDMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/scratch-cards.vue").then(m => m.default || m)
  },
  {
    name: scratch_45cards5LKpcP9oaDMeta?.name ?? "scratch-cards___en",
    path: scratch_45cards5LKpcP9oaDMeta?.path ?? "/en/scratch-cards",
    meta: scratch_45cards5LKpcP9oaDMeta || {},
    alias: scratch_45cards5LKpcP9oaDMeta?.alias || [],
    redirect: scratch_45cards5LKpcP9oaDMeta?.redirect,
    component: () => import("/app/apps/funrize/pages/scratch-cards.vue").then(m => m.default || m)
  }
]