<script lang="ts" setup>
const { t } = useT();
const prepareImgUrl = useImage();
const localPath = useLocalePath();
useAppInitData();

const imagePattern = `url(${prepareImgUrl(`/nuxt-img/error/error-bg.png`, {
	format: "webp"
})})`;
const handleError = () => clearError({ redirect: localPath("/") });
</script>

<template>
	<div class="error">
		<NuxtImg src="/nuxt-img/error/404.png" class="img" alt="404" width="360" height="360" format="webp" />
		<AText variant="tunja" as="h1" class="title text-comilla" :modifiers="['normal', 'center']">
			{{ t("Page not found") }}
			<NuxtImg src="/nuxt-img/error/sad_smile.png" format="webp" alt="sad emoji" width="48" height="48" />
		</AText>
		<AText class="subtitle" variant="turin" :modifiers="['center']">{{ t("Error 404") }}</AText>
		<AButton size="md" class="back-home" data-tid="error-return-home" @click="handleError">
			<AText variant="toledo" :modifiers="['normal']">{{ t("Go home") }}</AText>
			<NuxtIcon name="20/arrow-right-md" filled />
		</AButton>
	</div>
</template>

<style lang="scss" scoped>
.error {
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: transparent v-bind(imagePattern) 0 0 no-repeat;
	background-size: cover;
}

.title {
	margin: gutter(2) 0 gutter(1);
	display: flex;
	align-items: center;
	gap: gutter(1.5);

	@include media-breakpoint-down(lg) {
		font-size: 28px;
	}
}

.subtitle {
	@include media-breakpoint-down(lg) {
		font-size: 16px;
	}
}

.back-home {
	margin-top: gutter(3.75);
	gap: gutter(1.5);

	&:hover {
		background: var(--cary);
	}
}

.img {
	max-width: 100%;
	height: auto;
	display: block;
	object-fit: cover;
}
</style>
